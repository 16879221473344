import React, { useState, useEffect } from 'react';

import { getDealInformation } from "./O_MeetingSlotCard.helper"
import { TextField, MenuItem, InputLabel, FormControl, Select, Button, Grid, FormHelperText } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { stageMapping } from "./A_ICDropdown"
import { httpPost } from '../../../../../Utils/apis/apis';
import { AddEdge } from '../../../../../Utils/Helpers/crmDataApis/crmDataApis';
import { makeStyles } from "@material-ui/core";
import A_CircularIndeternment from '../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment';
import Alert from '@material-ui/lab/Alert';
import propTypes from "prop-types";
const useStyles = makeStyles({
    noOptions: {
        color: "red",
    }
});
const M_BookICMeeting = (props) => {
    const styles = useStyles();
    const [selectedValue, setSelectedValue] = useState('');
    const [textFieldValue, setTextFieldValue] = useState(props.isForReschedule ? props.dealNameWithStatus?.currentStatus : '');
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [dealData, setDealData] = useState([]);
    const [errors, setErrors] = useState({});
    const [guid, setGuid] = useState("");
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState('');

    useEffect(() => {
        if (!props.isForReschedule) {
            fetchData();
        }
        if (props.isForReschedule) {
            if (props.dealNameWithStatus?.currentStatus) {
                setTextFieldValue(props.dealNameWithStatus?.currentStatus);
                setDropdownOptions(getDropdownOptionsForApprovalStage(props.dealNameWithStatus?.currentStatus));
                setSelectedValue('');
            } else {
                setTextFieldValue('');
                setDropdownOptions([])
                setGuid("")
            }
        }
    }, [])
    useEffect(() => {
        const autoFillValues = () => {
            if (props.isForReschedule) {
                return;
            }
            if (autocompleteValue) {
                const selectedItem = dealData.find(item => item.name === autocompleteValue);
                if (selectedItem) {
                    setTextFieldValue(selectedItem["current status"]);
                    setDropdownOptions(getDropdownOptionsForApprovalStage(selectedItem["current status"]));
                    setSelectedValue('');
                    setGuid(selectedItem.id)
                }
            } else {
                setTextFieldValue('');
                setDropdownOptions([])
                setGuid("")
            }
        }
        autoFillValues()
    }, [autocompleteValue]);

    const fetchData = async () => {
        try {
            const defaultData = await getDealInformation();
            setDealData(defaultData)
        } catch {
            setDealData([])
        }
    }

    const validate = () => {
        const newErrors = {};

        if (!autocompleteValue && !props.isForReschedule) {
            newErrors.autocompleteValue = 'Investment is equired';
        }

        if (!selectedValue) {
            newErrors.selectedValue = 'IC Purpose is required';
        }

        return newErrors;
    };
    const handleSubmit = async () => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        let properties = {
            isApprovedData: "waiting for approval",
            ircPurpose: selectedValue,
            currentStatus: textFieldValue,
            ircDate: props?.IRCSlotDate,
            ircTime: props?.IRCTime,
            ircEndDate: props?.IRCSlotEndDate,
            subject: props?.meetingSubject,
            iCalUId: props?.ICalUId,
            ircEndTime: props?.ircEndTime,
            isCancelled: false,
            isRescheduled: false,
            requestType: "Original"
        }
        const payload = {
            "id": null,
            "label": "ircportal",
            "type": "vertex",
            "properties": properties,
            "security": {
                "read": {
                    "groupids": [
                        ""
                    ],
                    "users": [
                        ""
                    ]
                },
                "update": {
                    "groupids": [
                        ""
                    ],
                    "users": [
                        ""
                    ]
                },
                "full": {
                    "groupids": [
                        ""
                    ],
                    "users": []
                },
                "privateRecord": false
            },
            "edges": [
                {
                    "label": "Deal",
                    "guid": [
                        guid
                    ]
                }
            ],
            "submitToStoredProc": "",
            "duplicateDetection": "",
            "submitToStoredProcSource": "",
            "triggerEmail": {
                "emailCRMIds": [],
                "emailEdgeLabels": [],
                "emailConfigId": "getApprovalRequestEmailDetails"
            }

        }
        setLoading(true);
        const res = await httpPost(`/CRMData/insert`, payload, {}, true);
        if (res.status === 200 || res.status === 201 && res?.data?.id != null) {
            const dealAddEdgePayload = {
                properties: properties,
                sourceVertexId: guid,
                destinationVertexId: res?.data?.id,
                edgeLabel: "Deal",
            };
            const scheduledAddEdgePayLoad = {
                sourceVertexId: res?.data?.id,
                destinationVertexId: props?.IRCSlotCRMId,
                edgeLabel: "has_scheduled",
            };

            await AddEdge(dealAddEdgePayload);
            await AddEdge(scheduledAddEdgePayLoad);
            setLoading(false);
            setApiError('');
            setAutocompleteValue('');
            setTextFieldValue('');
            setSelectedValue(null);
            setErrors({});
            props?.onClose();
            props?.resetPage();
        } else {
            setLoading(false);
            setApiError(res?.response?.data?.MinervaMessage ? res?.response?.data?.MinervaMessage : 'Failed to Book Meeting. Please try later.');
        }
    }

    const handleRescheduleSubmit = async () => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});

        setLoading(true);
        let properties = {
            slotId: props?.IRCSlotCRMId,
            isApprovedData: "waiting for approval",
            currentStatus: textFieldValue,
            ircDate: props?.IRCSlotDate,
            ircTime: props?.IRCTime,
            ircEndDate: props?.IRCSlotEndDate,
            ircEndTime: props?.ircEndTime,
            isRescheduled: true,
            iCalUId: props?.ICalUId,
            ircPurpose: selectedValue
        }
        const payload = {
            id: props.ircPortalId,
            label: "ircportal",
            properties
        }
        navigator.clipboard.writeText(payload)
        const res = await httpPost(`/IRC/IrcRecord/RequestReschedule`, payload, {}, true);
        if (res.status === 200 || res.status === 201 && res?.data?.id != null) {
            setApiError('');
            setAutocompleteValue('');
            setTextFieldValue('');
            setSelectedValue(null);
            setErrors({});
            props.getReload()
            props?.onClose();
        } else {
            setApiError(res?.response?.data?.MinervaMessage ? res?.response?.data?.MinervaMessage : 'Failed to Book Meeting. Please try later.');
        }
        setLoading(false);
    }
    const getDropdownOptionsForApprovalStage = (approvalStage) => {
        return stageMapping[approvalStage] || [];
    };
    const handleAutocompleteChange = (event, newValue) => {
        setAutocompleteValue(newValue);
        if (errors.autocompleteValue) {
            setErrors((prevErrors) => ({ ...prevErrors, autocompleteValue: undefined }));
        }
    };

    const handleTextFieldChange = (event) => {
        setTextFieldValue(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        if (errors.selectedValue) {
            setErrors((prevErrors) => ({ ...prevErrors, selectedValue: undefined }));
        }
    }
    return (
        <div>
            <Grid container spacing={2}>
                {!props.isForReschedule &&
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                classes={{
                                    noOptions: styles.noOptions
                                }}
                                loading={dealData.length > 0 ? false : true}
                                noOptionsText={'Either Deal does not Exist or Deal is in Investment Stage'}
                                value={autocompleteValue}
                                onChange={handleAutocompleteChange}
                                options={dealData.map(option => option.name)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Investment *"
                                        error={!!errors.autocompleteValue}
                                        helperText={errors.autocompleteValue}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </>
                }
                {props.isForReschedule &&
                    <>
                        <Grid item xs={12}>
                            <TextField
                                value={props.dealNameWithStatus?.dealName}
                                label="Investment *"
                                error={!!errors.autocompleteValue}
                                helperText={errors.autocompleteValue}
                                fullWidth
                                disabled={true}
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <TextField
                        id="approvalStage"
                        label="Approval Stage"
                        fullWidth
                        value={props.isForReschedule ? props.dealNameWithStatus?.currentStatus : textFieldValue}
                        onChange={handleTextFieldChange}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.selectedValue}>
                        <InputLabel>IC Purpose *</InputLabel>
                        <Select
                            id={"ircPurpose"}
                            value={selectedValue}
                            onChange={handleSelectChange}
                            label="IC Purpose"
                            defaultValue={"Select"}
                            disabled={!textFieldValue}
                        >
                            {dropdownOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.selectedValue && (
                            <FormHelperText>{errors.selectedValue}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end" style={{ marginTop: 20 }}>
                    <span style={{ color: 'red', flex: 1, textAlign: 'left' }}>
                        {apiError && <Alert severity="error">{apiError}</Alert>}</span>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", textAlign: 'right' }}
                        onClick={props.isForReschedule ? handleRescheduleSubmit : handleSubmit}
                        disabled={loading}
                        endIcon={loading && <A_CircularIndeternment color="secondary" size={20} />}
                    >
                        Finish
                    </Button>
                </Grid>
            </Grid>
        </div >
    );


}
M_BookICMeeting.propTypes = {
    props: propTypes.any,
    onClose: propTypes.func,
    resetPage: propTypes.func,
    IRCSlotCRMId: propTypes.string,
    ircEndTime: propTypes.any,
    ICalUId: propTypes.any,
    meetingSubject: propTypes.any,
    IRCSlotEndDate: propTypes.any,
    IRCTime: propTypes.any,
    IRCSlotDate: propTypes.any,
    isForReschedule: propTypes.bool,
    dealNameWithStatus: propTypes.any,
    ircPortalId: propTypes.string,
    getReload: propTypes.any

}
export default M_BookICMeeting;

