import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as httpCall from "../../../../../Utils/apis/apis";
import { Box, Grid } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import calendarIcon from "../../../../../../src/Assets/Images/Defaults/meetingDef.jpg";
import { useCallback } from "react";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import { Skeleton } from "@material-ui/lab";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import { O_MeetingSlotCardCss } from "../MeetingSlotCard/O_MeetingSlotCardCss";
import {
  diff_hours,
  dateCheck,
} from "../MeetingSlotCard/O_MeetingSlotCard.helper";
import {
  ircSlotsEnums,
  IS_NOT_PE,
  IS_PE,
  RESULT,
} from "./ircSlots.Helper";
import {
  epochToDateFormatHelper,
} from "../../../../../Utils/Helpers/dateUtils";
import { ircSlotEnums } from "../MeetingSlotCard/mettingSlotCard.Helper";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import M_BookICMeeting from "../MeetingSlotCard/M_BookICMeeting";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
const useStyles = makeStyles(O_MeetingSlotCardCss);
const O_IRCSlots = (props) => {
  const classes = useStyles();
  const [slots, setSlots] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [ircPurpose, setIrcPurpose] = useState("");
  const [otherIRCPurpose, setOtherIRCPurpose] = useState("");
  const [iRCPurposeError, setIRCPurposeError] = useState(false);
  const [otherIRCPurposeError, setOtherIRCPurposeError] = useState(false);
  const [ircSlots, setIrcSlots] = useState();
  const [ircPortals, setIrcPortals] = useState();
  const [isOpen, setIsOpen] = useState(false)
  const [rescheduleData, setRescheduleData] = useState({})
  const { onReload } = props;
  let finalDataList = [];

  const handleDropdowns = (selectedValue) => {
    if (iRCPurposeError) {
      setIRCPurposeError(!iRCPurposeError);
    }
    if (selectedValue.value === "Other") {
      setShowTextField(true);
    } else {
      setShowTextField(false);
    }
    setIrcPurpose(selectedValue.value);
  };
  const handleTextboxes = (event) => {
    if (otherIRCPurposeError) {
      setOtherIRCPurposeError(!otherIRCPurposeError);
    }
    setOtherIRCPurpose(event.target.value);
  };

  const renderSlot = () => {
    if (ircSlots.length == 0) {
      return <></>
    }

    let ircSlotsL = ircSlots;


    ircSlotsL = ircSlotsL.map(slot => {
      let newSlot = slot;
      let slotDeals = []
      ircPortals.forEach((ircPortal) => {
        if (slot.id == ircPortal.ircSlotId) {
          slotDeals.push(ircPortal)
        }
      })

      newSlot.deals = slotDeals;

      return newSlot;
    });

    ircSlotsL.sort((slotA, slotB) => slotA?.properties?.ircDate - slotB?.properties?.ircDate)
    let closedAfter = props.closedAfter ? props.closedAfter : 0;
    let warningAfter = props.warningAfter ? props.warningAfter : 0;

    ircSlotsL.forEach((slot) => {
      let slotDate = FromEpochFormat(slot?.properties?.ircDate);
      let disableBook = diff_hours(slotDate) <= closedAfter;
      let displayMessage =
        closedAfter <= warningAfter &&
        diff_hours(slotDate) > closedAfter &&
        diff_hours(slotDate) <= warningAfter;

      const checkIsBookDisabled = () => {
        let timeForSlot = slot.properties.ircEndDate - slot.properties.ircDate;
        let noOfPortalsAllowed = Math.floor(timeForSlot / (30 * 60));
        let noOfPortalsBooked = slot.deals.length;
        let isCurrentBookedSlot = slot?.deals.find((item) => item.id === props?.ircPortalId);
        return noOfPortalsBooked >= noOfPortalsAllowed || isCurrentBookedSlot;
      }

      let isBookDisabled = checkIsBookDisabled();
      const handleRescheduleICMeeting = () => {
        let currentIRCDate = getFormattedDate(slotDate, ircSlotEnums.DATE_TIME_FORMAT)
        let currentIRCEndDate = slot?.properties?.ircEndDate || "";
        setRescheduleData({
          IRCRecordId: props.ircPortalId,
          IRCdate:
            currentIRCDate
              ? `${currentIRCDate} ${currentIRCEndDate &&
              `-${epochToDateFormatHelper(currentIRCEndDate, "HH:mm")}`
              }`
              : "",
          IRCSlotDate: slot?.properties?.ircDate,
          IRCSlotEndDate: slot?.properties?.ircEndDate || "",
          IRCSlotCRMId: slot?.id || "",
          meetingSubject: slot?.properties?.subject || "",
          IRCTime: slot?.properties?.ircTime || "",
          ICalUId: slot?.properties?.iCalUId || "",
          ircEndTime: slot?.properties?.ircEndTime || "",
        })
        setIsOpen(true);
      };

      let detail = () => {
        return (
          <Box className={classes.detailFirstBox} style={{ width: "98%" }}>
            <Grid container spacing={8} alignItems="start">
              <Grid item xs={2}>
                <img src={calendarIcon} alt="" />
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.detailSecondBox}>
                  <Typography
                    className={classes.typographyStyle}
                    style={{ fontSize: "1.2rem" }}
                  >
                    {slot?.properties?.subject}
                  </Typography>
                  <Typography className={classes.typographyStyle}>
                    {getFormattedDate(slotDate, ircSlotsEnums.DATE_FORMATE)}
                    {slot.properties?.ircEndDate && "-"}{" "}
                    {getFormattedDate(
                      FromEpochFormat(slot?.properties?.ircEndDate),
                      ircSlotEnums.TIME_FORMAT
                    )}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4} justifyContent="end" className="text-center">
                <A_Button
                  onClick={handleRescheduleICMeeting}
                  color={displayMessage ? "secondary" : "primary"}
                  label="Book"
                  disabled={
                    isBookDisabled
                  }
                />{" "}
                {!(disableBook || slot?.properties?.bookingStatus) &&
                  displayMessage ? (
                  <span className={classes.timePeriod}>
                    {props.content &&
                      (props.content.closingText ||
                        props.content.closingText === "")
                      ? props.content.closingText
                      : "Closing Soon!"}
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Box style={{ paddingTop: "0.8rem" }}>
              <A_Divider />
            </Box>
          </Box>
        )
      };
      let componentJSON = {
        Date: slotDate,
        Detail: detail,
      };

      if (dateCheck(slotDate, props.noOfDays)) {
        finalDataList.push(componentJSON);
      }
    })
    setSlots(finalDataList);
  };
  const getSlot = useCallback(async () => {
    try {
      setIsLoading(true);

      const [result, resultRecord] = await Promise.all([
        httpCall.httpPost(`/IRC/getIRCSlot`, RESULT),
        httpCall.httpGet(`/IRC/v3/getIRCRecord`)
      ]);

      setIrcSlots(result);
      setIrcPortals(resultRecord);
    } catch (error) {
      console.error('Error fetching IRC data:', error);
    } finally {
      setIsLoading(false);
    }
  });

  const CloseBookForm = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    getSlot();
  }, []);
  useEffect(() => {
    if (ircPortals && ircSlots) {
      renderSlot();
    }
  }, [ircPurpose, otherIRCPurpose, ircSlots, ircPortals]);

  return (
    <>
      <Box className={classes.skeleton}>
        {!props.modify ? (
          <A_Select
            className={classes.inputcontrol}
            fullWidth={true}
            variant="standard"
            native={true}
            options={
              props.ircConfig && props.ircConfig.isPE === true
                ? IS_PE
                : IS_NOT_PE
            }
            onChange={handleDropdowns}
            label={props.purposeLabel || ircSlotsEnums.IRC_PURPOSE}
            required={true}
            error={iRCPurposeError}
          />
        ) : (
          ""
        )}
        {showTextField ? (
          <A_TextField
            className={classes.inputcontrolTextField}
            onChange={handleTextboxes}
            variant="standard"
            label={props.otherPurposeLabel || ircSlotsEnums.OTHER_IRC_PURPOSE}
            required={true}
            error={otherIRCPurposeError}
          />
        ) : (
          ""
        )}
        {loading ? (
          <>
            <Skeleton variant="text" width={100} height={100} />
          </>
        ) : slots.length > 0 ? (
          slots.map((slot) => {
            return slot.Detail();
          })
        ) : (
          ircSlotsEnums.NO_SLOT_AVALIABLE
        )}
      </Box>
      <A_SimpleDialog
        open={isOpen}
        height="1000px"
        title={"Book IC Slot"}
        fullScreen={false}
        fullwidth={true}
        maxWidth={"sm"}
        onClose={CloseBookForm}
        dialogContent={
          <ErrorBoundary>
            <M_BookICMeeting
              {...rescheduleData}
              open={isOpen}
              refresh={false}
              isForReschedule={true}
              onClose={CloseBookForm}
              dealNameWithStatus={props.dealNameWithStatus}
              ircPortalId={props.ircPortalId}
              resetPage={onReload}
              getReload={props.getReload}
            />
          </ErrorBoundary>
        }
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}), dispatch);
}
O_IRCSlots.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
  maxHeight: propTypes.any,
  onClose: propTypes.func,
  closedAfter: propTypes.any,
  modify: propTypes.bool,
  onReload: propTypes.func,
  ircConfig: propTypes.any,
  getReload: propTypes.func,
  warningAfter: propTypes.any,
  idLabel: propTypes.any,
  noOfDays: propTypes.any,
  purposeLabel: propTypes.any,
  otherPurposeLabel: propTypes.any,
  dealNameWithStatus: propTypes.any,
  ircPortalId: propTypes.string
};
O_IRCSlots.defaultProps = {
  onReload: () => { },
};
export default connect(mapStateToProps, mapDispatchToProps)(O_IRCSlots);
