import * as React from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  AppBar,
  CircularProgress,
} from "@material-ui/core";
import {
  getComponentFromConfig,
  hasCheckList,
  checkIsCheckListAvailable,
} from "../../../Organisms/Common/GenericList/Helper/GenericList.Helper";
import { useParams } from "react-router";
import propTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./M_Tabs.css";

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function M_Tabs(props) {
  const [value, setValue] = React.useState(0);
  // const [hideCheckListTabs, setHideCheckListTabs] = React.useState(false);
  const [checkListData, setCheckListData] = React.useState({});
  const [tabData, setTabData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [tabSectionData, setTabSectionData] = React.useState([]);
  const { crmId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const getCheckListData = async () => {
    setIsLoading(true);
    let checkListTempData = await checkIsCheckListAvailable(crmId);
    // setHideCheckListTabs(!checkListTempData["hasCheckList"]);
    setCheckListData(checkListTempData);
    setValidIndex();
    setIsLoading(false);
  };
  React.useEffect(() => {
    props.tabData.map((element, index) => {
      let checkCheckList = hasCheckList(element);
      if (checkCheckList) {
        getCheckListData(index, props.tabData.length);
      }
      return;
    });
  }, []);
  React.useEffect(() => {
    setValidIndex();
    setTabSectionData(props.tabData);
  }, [props.tabData]);
  const getShowHide = (isCheckList, element) => {
    if (element && element.validate && Array.isArray(element.validate)) {
      let isHide = element.validate.join("").trim() === "";
      return isHide;
    }
    return false;
  };
  const setValidIndex = () => {
    setValue(0);
  };
  const getTabData = () => {
    let tempTabData = (
      <>
        <AppBar
          position="static"
          color="default"
          style={{ borderTop: "0.4rem" }}
        >
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabSectionData
              .filter((ele) => {
                if (ele.checklist) {
                  // return !hideCheckListTabs;
                  return true;
                } else if (ele.label && ele.label === "Responses") {
                  return false
                } else {
                  return true
                }
              })
              .map((element, index) => {
                let checkCheckList = hasCheckList(element);
                return (
                  <CustomTab
                    key={index}
                    label={element.label}
                    hidden={getShowHide(checkCheckList, element)}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
        </AppBar>
        {tabSectionData
          .filter((ele) => {
            if (ele.checklist) {
              // return !hideCheckListTabs;
              return true
            } else if (ele.label && ele.label === "Responses") {
              return false
            } else {
              return true
            }
          })
          .map((element, index) => {
            let checkCheckList = hasCheckList(element);
            if (checkCheckList) {
              element = { ...element, ircPortalData: props.ircPortalData }
            }
            return (
              <TabPanel key={index} value={value} index={index}>
                {getComponentFromConfig(
                  element,
                  checkCheckList ? checkListData : null,
                  props.content ? props.content : {},
                  props.reload ? props.reload : {}
                )}
              </TabPanel>
            );
          })}
      </>
    );
    setTabData(tempTabData);
  };
  React.useEffect(getTabData, [
    // hideCheckListTabs,
    false,
    checkListData,
    value,
    props.tabData,
  ]);
  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : tabData ? (
        tabData
      ) : (
        "Please Wait"
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: propTypes.any,
  value: propTypes.any,
  index: propTypes.any,
  reload: propTypes.func,
};
M_Tabs.propTypes = {
  tabData: propTypes.any,
  content: propTypes.object,
  reload: propTypes.func,
  ircPortalData: propTypes.object
};
