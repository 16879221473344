/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import M_CheckList from "../../../Molecules/Common/Checklist/M_CheckList";
import O_Mail from "../IRC_Mail/O_Mail";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import O_IRCSlots from "../IRCSlots/O_IRCSlots";
import { ircConfig, labelName } from "../../../../../Utils/config/config";
import { getDealChecklistCompletion, getDealNameWithStatus } from "../MeetingSlotCard/O_MeetingSlotCard.helper";
import { useParams } from "react-router";
import { stageForSendICDisable } from "../MeetingSlotCard/A_ICDropdown";

function O_CheckList(props) {
  const [bookIRC, setBookIRC] = React.useState(false);
  const [dealNameWithStatus, setDealNameWithStatus] = React.useState({})
  const [dealChecklistCompletion, setDealChecklistCompletion] = React.useState({})
  const [loading, setloading] = React.useState(true)
  const { data: primaryData } = props.currentPage;
  const { crmId, pageUrl } = useParams();
  const { urls = [], currentPage, redirect = false } = props;
  const checkStatus = currentPage?.data?.currentStatus === "Investment"

  const handleBookIRC = () => {
    setBookIRC(true);
  };
  const handleIRCForm = () => {
    setBookIRC(false);
  };
  const getReload = () => {
    props.reload();
  };

  React.useEffect(() => {
    const getDealChecklistInfo = async () => {
      setloading(true);
      let [dealNameWithStatus, dealChecklistCompletion] = await Promise.all([getDealNameWithStatus(crmId), getDealChecklistCompletion(crmId)]);

      setDealNameWithStatus(dealNameWithStatus?.[0] || {});
      setDealChecklistCompletion(dealChecklistCompletion || {})
      setloading(false);
    }

    getDealChecklistInfo()
  }, [])


  return (
    <>
      <div>
        {
          redirect ?
            checkStatus ?
              <a href={`${pageUrl}/${crmId}/${urls[0]}`}>Deal Checklist</a> : <a href={`${pageUrl}/${crmId}/${urls[1]}`}>Deal Checklist</a> : ""
        }
        {(!!props.ircPortalData?.isCancelled || !!props.ircPortalData?.isRescheduled) &&
          <Box
            bgcolor={!!props.ircPortalData?.isCancelled && !props.ircPortalData?.isRescheduled ? "#ffd3d3" : "#fcf8e3"}
            color={!!props.ircPortalData?.isCancelled && !props.ircPortalData?.isRescheduled ? "red" : "#fec85f"}
            width={"100%"}
            padding={"1rem"}
            marginY={"1rem"}
          >
            {!!props.ircPortalData?.isCancelled && !props.ircPortalData?.isRescheduled && props.content?.ircCancelMessage}
            {!!props.ircPortalData?.isRescheduled && !props.ircPortalData?.isCancelled && props.content?.ircRescheduleMessage}
            {!!props.ircPortalData?.isRescheduled && !!props.ircPortalData?.isCancelled && props.content?.ircCancelRescheduleMessage}
          </Box>
        }
        <Box paddingTop={"2rem"} display={"flex"} justifyContent={"end"}>
          <A_Button
            label={loading ? "Loading..." : "Reschedule"}
            color={"primary"}
            onClick={handleBookIRC}
            disabled={loading || !!props.ircPortalData?.isRescheduled || dealNameWithStatus?.currentStatus == props.content?.stageToDisableReschedule}
          />
          {stageForSendICDisable.includes(props.ircPortalData?.currentStatus) || dealChecklistCompletion?.[props.ircPortalData?.currentStatus] ? (
            <O_Mail
              content={props.content}
              label={
                props.content && props.content.ircMailButtonLabel
                  ? props.content.ircMailButtonLabel
                  : labelName.IRC_BUTTON
              }
              ircID={props.ircPortalData?.id}
              formData={{}}
              reload={getReload}
            />
          ) : (
            <A_Button
              label={
                dealNameWithStatus?.currentStatus ?
                  (
                    props.content && props.content.ircMailButtonLabel
                      ? props.content.ircMailButtonLabel
                      : labelName.IRC_BUTTON
                  ) : "Loading..."
              }
              disabled={true}
              color={"primary"}
            />
          )}
        </Box>
        <A_SimpleDialog
          open={bookIRC}
          formTemplateId
          title={ircConfig.RESCHEDULE}
          fullScreen={false}
          height="400px"
          fullwidth={true}
          maxWidth={"md"}
          onClose={handleIRCForm}
          closePopUp={() => {
            handleIRCForm;
          }}
          dialogContent={
            <O_IRCSlots
              onClose={handleIRCForm}
              getReload={getReload}
              modify={true}
              idLabel={
                props.content &&
                props.content &&
                props.content.rescheduleLabel &&
                props.content.rescheduleLabel.id
              }
              dealNameWithStatus={dealNameWithStatus}
              ircConfig={props.content && props.content.rescheduleLabel}
              ircPortalId={props.ircPortalData?.id}
            />
          }
        />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

export default connect(mapStateToProps)(O_CheckList);
