export const stageMapping = {
    "Pre-Screening": ["IC Update", "IC Feedback", "Screening Approval"],
    "Screening": ["IC Update", "IC Feedback", "Screening Approval"],
    "NBO/RFQ": ["IC Update", "IC Feedback", "NBO/RFQ Submission Approval"],
    "BO/RFP": ["IC Update", "IC Feedback", "BO/RFP Submission Approval"],
    "Approval to Invest": ["IC Update", "IC Feedback", "Approval to Invest"],
    "NBO / RFQ": ["IC Update", "IC Feedback", "NBO/RFQ Submission Approval"],
    "BO / RFP": ["IC Update", "IC Feedback", "BO/RFP Submission Approval"],
};

export const stageForSendICDisable = ["Pre-Screening", "Screening"]
