/* eslint-disable */
import { take, put } from "redux-saga/effects";
import { getIrcSlotActionTypes } from "../actions/actionTypes";
import * as getIrcSlotAction from "../actions/getIrcSlotAction";
import * as httpCall from "../../Utils/apis/apis";

export function* getIrcSlotSaga() {
  while (true) {
    const action = yield take(getIrcSlotActionTypes.GET_IRC_SLOT);
    const { payload } = action.payload;
    try {
      const resData = yield httpCall.httpGet(`/IRC/v3/getIRCRecord`);
      yield put(
        getIrcSlotAction.getIrcSlotSucceeded({
          data: resData,
        })
      );
    } catch (e) {
      //TODO - set toast message here
      //TODO - get errorcode message from message file using function
      // TODO - check status code in case of error, success or anything
      // pass status code to a generic function and that will loop through errorcode
      // and will return appropriate message for api
      getIrcSlotAction.getIrcSlotFailed({
        errorCode: "",
        message: "Irc get ERROR",
        status: false,
      });
    }
  }
}
