import { httpPost } from "../../../../../../../Utils/apis/apis";

/* eslint-disable */
export const verifyValidChartRequest = (chartRequest) => {
  let isChartRequestValid = true;
  const paramCount =
    chartRequest && chartRequest.parameters
      ? Object.keys(chartRequest.parameters).length
      : 0;
  let chartNameIndex = 0;
  if (paramCount >= 2) {
    chartNameIndex = 1;
  }
  let chartKey = JSON.stringify(chartRequest);
  let storedChartKeys = localStorage.getItem("chartStorageKeys");
  let chartKeysArr = storedChartKeys ? storedChartKeys.split("__") : [];
  if (chartKeysArr.indexOf(chartKey) > -1) {
    isChartRequestValid = false;
  } else {
    //ChartStorageKey should be config driven in whole app
    localStorage.setItem("chartStorageKeys", storedChartKeys + "__" + chartKey);
  }
  return isChartRequestValid;
};

export const hasPageFilter = (currentPage) => {
  // check if page has the parameters
  let result = true;
  if (currentPage && currentPage.pageConfig && currentPage.pageConfig.toolbar) {
    let toolbar = JSON.parse(currentPage.pageConfig.toolbar);
    let filters =
      toolbar && toolbar.primary && toolbar.primary.filters
        ? toolbar.primary.filters
        : [];
    if (!(filters.length > 0)) {
      result = false;
    }
  } else {
    result = false;
  }
  return result;
};

export const toolbarFilterData = (props, templateId) => {
  let toolbarFilters = [];
  try {
    if (props.currentPage &&
      props.currentPage.pageConfig &&
      props.currentPage.pageConfig.toolbar &&
      typeof props.currentPage.pageConfig.toolbar === "string") {
      let toolbar = JSON.parse(props.currentPage.pageConfig.toolbar);
      toolbarFilters = toolbar && toolbar.primary && toolbar.primary.filters ?
        toolbar.primary.filters.filter(filter => {
          if (filter.templateIds) {
            let templatePresent = false;
            filter.templateIds.map(filtertemplateId => {
              if (filtertemplateId === templateId) {
                templatePresent = true;
                return;
              }
            })
            return templatePresent;
          }
          else {
            return false;
          }
        })
        : []
    }
  }
  catch (e) {
    toolbarFilters = [];
  }
  return toolbarFilters;
}
export const handleJSON = (str) => {
  try {
    const parsedObject = JSON.parse(str);
    if (parsedObject && parsedObject.label !== undefined) {
      return parsedObject.label;
    }
  } catch (error) {
    // Parsing failed, return the original string
  }
  return str;
}

export const getEmailAndNamesGremlinSP = async (gremlinSP, emailsString) => {
  let url = `/GenericGremlin/generic/${gremlinSP}`;
  let requestPayload = {
    "@emails": emailsString,
  };
  const result = await httpPost(url, requestPayload);
  return result;
};

export const addNamesForEmails = async (config, result) => {
  let uniqueEmails = {};
  let indexesToChange = [];
  config.NamesByEmails.categories.forEach((category) => {
    let categoryIndex = result.data.categories.indexOf(category);
    indexesToChange.push(categoryIndex);
    if (categoryIndex >= 0) {
      result.data.series.forEach((element) => {
        if (!uniqueEmails[element.data[categoryIndex]]) {
          uniqueEmails[element.data[categoryIndex]] = 1;
        }
      })
    }
  });
  let emailsParam = JSON.stringify(Object.keys(uniqueEmails));
  let emailsWithNames = await getEmailAndNamesGremlinSP(config.NamesByEmails.spName, emailsParam);
  let emailsWithNamesMap = {};
  Array.isArray(emailsWithNames) && emailsWithNames?.forEach((item) => {
    emailsWithNamesMap[item.email] = { firstName: item.firstName, lastName: item.lastName };
  });
  let newSeries = result.data.series.map((item) => {
    let itemData = item.data;
    indexesToChange.forEach((categoryIndex) => {
      if (!config.NamesByEmails?.excludedEmails?.includes(itemData[categoryIndex])) {
        if (emailsWithNamesMap[itemData[categoryIndex]]) {
          itemData[categoryIndex] = `${emailsWithNamesMap[itemData[categoryIndex]].firstName} ${emailsWithNamesMap[itemData[categoryIndex]].lastName}`;
          return;
        }
        switch (config.NamesByEmails?.showForNoContact) {
          case "dash": {
            itemData[categoryIndex] = "-";
            break;
          }
          case "splitEmail": {
            let mailLocalPart = itemData[categoryIndex].split("@")[0];
            let nameArray = mailLocalPart?.split(".") || []
            let firstName = (nameArray?.[0] && (nameArray?.[0].charAt(0).toUpperCase() + nameArray?.[0].slice(1))) || "";
            let lastName = (nameArray?.[1] && (nameArray?.[1].charAt(0).toUpperCase() + nameArray?.[1].slice(1))) || "" || "";

            itemData[categoryIndex] = `${firstName} ${lastName}`
          }
          default: {
            break;
          };
        }
      }
    })
    return { ...item, data: itemData };
  })
  return newSeries;
}