import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import propTypes from "prop-types";
import "./M_Tabs.css";
import { getEmailAndNamesGremlinSP, getIRCEmailsByIRCPortalId } from "../../../Organisms/Common/MeetingSlotCard/O_MeetingSlotCard.helper";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { getFileName } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import { downloadDocument } from "../Renderers/Views/PreviousTemplate/previousUpload.helper";
import { epochToDateFormatHelper } from "../../../../../Utils/Helpers/dateUtils";

export default function M_TabsRemark(props) {
  const [emailData, setEmailData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedMail, setSelectedMail] = React.useState({})
  const [isDocumentDownloading, setIsDocumentDownloading] = React.useState(false)

  const getBulkDocuments = async () => {
    if (props?.documentWithRemark?.container) {
      let container = props?.documentWithRemark && props?.documentWithRemark?.container;
      let docs = await getFileName(props.id, container);
      if (docs !== "") {
        return docs;
      } else {
        return [];
      }
    } else {
      return null
    }
  }
  const getMails = async () => {
    try {
      let payload = props.payload ? props.payload : "";
      if (props.id && payload) {
        setIsLoading(true);
        if (
          payload &&
          payload.sourceId &&
          typeof payload.sourceId === "string" &&
          payload.sourceId.toLowerCase() == "=ircid"
        ) {
          payload.sourceId = props.id ? props.id : "";
        }

        const [emailsResult, docsResult] = await Promise.all([getIRCEmailsByIRCPortalId(props.id), getBulkDocuments()])

        if (emailsResult && Array.isArray(emailsResult)) {
          let uniqueEmails = {}
          let docsMap = {};
          docsResult.forEach((item) => {
            docsMap[item.file] = item;
          })
          emailsResult.forEach(item => {
            let recipients = JSON.parse(item.recipients);
            let ccUsers = JSON.parse(item.ccUsers);
            recipients.forEach((user) => {
              uniqueEmails[user] = 1;
            })
            ccUsers.forEach((user) => {
              uniqueEmails[user] = 1;
            })
          });
          let emailsParam = JSON.stringify(Object.keys(uniqueEmails));
          let emailsWithNames = await getEmailAndNamesGremlinSP(emailsParam);
          let emailsWithNamesMap = {};
          emailsWithNames.forEach((element) => {
            emailsWithNamesMap[element.email] = `${element.firstName} ${element.lastName}`
          })

          let newResponse = emailsResult.map((element) => {
            let recipients = JSON.parse(element.recipients)
            let ccUsers = JSON.parse(element.ccUsers)
            let recipientNames = recipients.map(user => emailsWithNamesMap[user] || null);
            let ccUserNames = ccUsers.map(user => emailsWithNamesMap[user]) || null;
            let documents = [];
            let mailDate = epochToDateFormatHelper(element.createdDate, "DD/MM/YYYY HH:mm");
            if (element.attachments !== "") {
              documents = element.attachments.split(",");
              documents = documents.map((doc) => {
                let docStringElements = doc.split('/');
                docStringElements.shift();
                return docsMap[docStringElements.join("/")] || null;
              })
            }

            return { ...element, recipients: recipientNames, ccUsers: ccUserNames, documents, mailDate }
          })

          newResponse.sort((a, b) => {
            if (a.createdDate - b.createdDate) {
              return -1;
            } else if (b.createdDate - a.createdDate) {
              return 1;
            } else {
              return 0;
            }
          })
          setEmailData(newResponse);
        } else {
          setEmailData([])
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getMails();
  }, [props.id]);
  // TO-Do Comment this code to resolve Lint
  // const renderDocument = (item) => {
  //   let files =
  //     item.attachments && typeof item.attachments === "string"
  //       ? item.attachments.split(",")
  //       : [];
  //   if (files.length == 0) {
  //     return <></>;
  //   }
  //   return (
  //     <div>
  //       <Typography className="document-head">Documents</Typography>
  //       {files.map((value, index) => {
  //         let path = value.split("/");
  //         let fileName = path[path.length - 1];
  //         return (
  //           <div key={index}>
  //             <Typography className="document-filename">
  //               <span
  //                 className="document"
  //                 onClick={() => {
  //                   fileDownload(fileName, value);
  //                 }}
  //               >
  //                 {fileName}
  //               </span>
  //             </Typography>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };
  // const fileDownload = (filename, filepath) => {
  //   const url = `${process.env.DOCUMENT_HELPER}`;
  //   const httpConfig = commonFunctions.getHttpConfig();
  //   //important
  //   // let subdomain = "dev";
  //   // new URL(window.location.href).hostname.split(".")[0]
  //   let subdomain = new URL(window.location.href).hostname.split(".")[0];
  //   let header = {
  //     filename: filepath,
  //     type: "email",
  //     "file-operation": "download",
  //     "process-file": "yes",
  //     "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
  //     subdomain: subdomain,
  //     Authorization: httpConfig.headers.Authorization,
  //   };
  //   const formData = {};
  //   httpPost(url, formData, { responseType: "blob", headers: header })
  //     .then((response) => {
  //       const url1 = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url1;
  //       link.setAttribute("download", filename);
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  // };

  const handleMailClose = () => {
    setSelectedMail({});
  }

  const handleDownloadDoc = async (e) => {
    setIsDocumentDownloading(true)
    let container = props.documentWithRemark && props.documentWithRemark.container;
    await downloadDocument(e, container);
    setIsDocumentDownloading(false)
  };
  return (
    <>
      <A_SimpleDialog
        open={selectedMail?.subject}
        height="1000px"
        title={"Email"}
        fullScreen={false}
        fullwidth={true}
        maxWidth={"sm"}
        onClose={handleMailClose}
        dialogContent={<MailBox item={selectedMail} handleDownloadDoc={handleDownloadDoc} isDocumentDownloading={isDocumentDownloading} />}
      />
      <Box style={{ paddingTop: "1.2rem" }} >
        <div className="mail_container">
          <div className="progress_box">{isLoading && <A_CircularIndeternment />}</div>
          {!isLoading && emailData
            ? emailData.map((item, index) => {
              if (!item) {
                return <></>
              }

              const handleOpenMail = () => {
                setSelectedMail(item);
              }
              return (
                <>
                  <div key={index} className="mail_subject" onClick={handleOpenMail}>
                    <span>{item.subject} | {item.mailDate}</span>
                  </div>
                  <Attachments item={item} handleDownloadDoc={handleDownloadDoc} isDocumentDownloading={isDocumentDownloading} />
                  <div className="line" />
                </>
              );
            })
            : ""}
        </div>
      </Box >
    </>
  );
}

M_TabsRemark.propTypes = {
  content: propTypes.object,
  id: propTypes.string,
  payload: propTypes.any,
  documentWithRemark: propTypes.object,
};

const MailBox = ({ item, handleDownloadDoc, isDocumentDownloading }) => {
  if (!item) {
    return <></>
  }
  let recipientsLength = item.recipients?.length;
  return (
    <div className="mail_tile">
      <div className="mail_prop">
        <span className="prop_key">To :</span>
        <div>
          {
            item.recipients?.map((recipient, index) => <> <span key={index}>{recipient}</span>{index != (item.recipients.length - 1) && <span>, </span>}</>)
          }
        </div>
      </div>
      <div className="mail_prop">
        <span className="prop_key">CC :</span>
        <div>
          {
            item.ccUsers?.map((recipient, index) =>
              <>
                {recipient && <span key={index}>{recipient}</span>}
                {index != (recipientsLength - 1) && <span>, </span>}
              </>
            )
          }
        </div>
      </div>
      <div className="mail_prop">
        <span className="prop_key">Subject : </span>
        <span>{item.subject}</span>
      </div>
      <div className="mail_prop">
        <span className="prop_key" >Request :</span>
        <div dangerouslySetInnerHTML={{
          __html: item.body ? item.body : "",
        }}
        />
      </div>
      <div>
        <span className="prop_key">Attachments</span>
        <Attachments item={item} handleDownloadDoc={handleDownloadDoc} isDocumentDownloading={isDocumentDownloading} />
      </div>
    </div>
  )
}

MailBox.propTypes = {
  item: propTypes.object,
  handleDownloadDoc: propTypes.func,
  isDocumentDownloading: propTypes.bool
};

const Attachments = ({ item, isDocumentDownloading, handleDownloadDoc }) => {
  return (
    item.documents
      ? <>
        {
          item.documents.map((doc) => {
            if (!doc?.file) {
              return <></>
            }
            return (
              <div className="document_list" key={doc.file}>
                <div className={`dot ${isDocumentDownloading ? "dotDisabled" : ""}`} />
                <Typography >
                  <button
                    className={`document ${isDocumentDownloading ? "documentDisabled" : ""}`}
                    disabled={isDocumentDownloading}
                    onClick={() =>
                      handleDownloadDoc({
                        ...doc,
                        fileDisplayName: doc.file.split("/").pop(),
                      })
                    }
                  >
                    {doc.file.split("/").pop()}
                  </button>
                </Typography>
              </div>
            );
          })
        }
      </>
      : ""

  )
}

Attachments.propTypes = {
  item: propTypes.object,
  handleDownloadDoc: propTypes.func,
  isDocumentDownloading: propTypes.bool
}